<template>
  <section
    class="login-success min-h-screen min-w-full bg-base-300 flex items-center justify-center "
  >
    <div class="card bg-white text-center shadow-2xl">
      <div class="card-body">
        <p>Please wait...</p>
        <div class="justify-center card-actions">
          <!-- <button class="btn no-animation  loading" disabled>
              Redirecting
            </button> -->
          <Loader />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "@/axios";
import Loader from "@shared/loader";
// import { mapGetters } from "vuex";
// import VueCookies from "vue-cookies";

export default {
  name: "login-success",
  components: {
    Loader,
  },
  props: [],
  data() {
    return {
      auth_code: null,
    };
  },
  computed: {
    // ...mapGetters(["getValidatedData"]),
  },
  async mounted() {
    const auth_code = this.$route?.query?.code ?? null;
    const state = this.$route?.query?.state ?? null;
    if (state.startsWith("existing")) {
      await this.getAuthToken(auth_code, state, "existing");
    } else {
      await this.getAuthToken(auth_code, state, "new");
    }
  },
  methods: {
    async getAuthToken(code, state, userType) {
      if (code) {
        try {
          const url =
            userType === "existing"
              ? `global-auth/code`
              : `global-auth/register-via-sp-initiated-sso`;
          const response = await axios.post(url, {
            code: code,
            state,
          });
          console.log("response", response);
          if( response.data.next_ui === 'SSO_EMAIL_ID_MISMATCH') {
            this.$toast.error(
              response?.data?.data?.message ||
                "Unable to verify you email through Okta."
            );
            this.$router.push("/login");
            return;
          } else if (response?.data && userType === 'existing') {
              let user = {};
              // case_entity_id
              let { user_id, user_name, email, additional = false } = response?.data;
              user = { user_id, email, user_name };
              // console.log()
  
              this.$store.dispatch("setLoginSession", {
                  token: response?.data?.access_token,
                  refresh_token: response?.data?.refresh_token,
                  userLoggedIn: true,
                  user,
                  additional,
              });

              // reset global auth data
              this.$store.dispatch("manageGlobalAuthData", null);
              this.$router.push({name: "dashboard.view"});
              return;
            }
            if (response?.data && userType === 'new') {
             if(response.data?.next_ui === "REGISTRATION_AWAITING_APPROVAL") {
              this.$toast.success("Your registration is successful, waiting for the Approval.");
              this.$router.push("/login");
              return;
             } else if(response.data?.next_ui === 'REGISTRATION_COMPLETE_LOGIN') {
                this.$toast.success("Your registration is successful");
                this.$router.push("/login");
                return;
             }
            }

          // if (
          //   response?.data?.next_ui === "SSO_EMAIL_ID_MISMATCH" &&
          //   userType === "existing"
          // ) {
          //   this.$toast.error(
          //     response?.data?.data?.message ||
          //       "Unable to verify you email through Okta."
          //   );
          //   this.$router.push("/login");
          //   return;
          // }

          // if (
          //   response?.data.next_ui === "SSO_LOGIN_SUCCESS" &&
          //   userType === "existing"
          // ) {
          //   let user = {};
          //   let {
          //     user_id,
          //     user_name,
          //     email,
          //     additional = false,
          //   } = response?.data;
          //   user = { user_id, email, user_name };
          //   this.$store.dispatch("setLoginSession", {
          //     token: response?.data?.access_token,
          //     refresh_token: response?.data?.refresh_token,
          //     userLoggedIn: true,
          //     user,
          //     additional,
          //   });
          //   // reset global auth data
          //   this.$store.dispatch("manageGlobalAuthData", null);
          //   this.$router.push({ name: "dashboard.view" });
          //   return;
          // }

          // if (
          //   response?.data?.next_ui === "REGISTRATION_AWAITING_APPROVAL" &&
          //   userType === "new"
          // ) {
          //   this.$toast.success(
          //     "Your registration is successful, waiting for the Approval."
          //   );
          //   this.$router.push("/login");
          //   return;
          // }

          // if (
          //   response?.data?.next_ui === "REGISTRATION_COMPLETE_LOGIN" &&
          //   userType === "new"
          // ) {
          //   this.$toast.success("Your registration is successful");
          //   this.$router.push("/login");
          //   return;
          // }
          // this.$router.push("/login");
        } catch (error) {
          console.log("err", error);
          this.$toast.error(
            error?.response?.data?.detail || "Something went wrong"
          );
          if (userType === "new") {
            this.$router.push("/login");
          } else {
            this.$store.dispatch("logoutUser");
          }
        }
      }
    },
  },
};
</script>
